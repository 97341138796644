import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';

import LoadingPage from "../components/dashboard/LoadingPage";
import "./BillingPage.css";
import BillingTable from '../components/billing/BillingTable';
import ConsultedHectaresGraph from '../components/billing/ConsultedHectares';
import MonthlyCostChart from '../components/billing/MonthlyCost';
import AuraChart from '../components/billing/AuraChart';
import SenseChart from '../components/billing/SenseChart';
import TotalMonitoring from '../components/billing/TotalMonitoring';
import { fetchBillingReportHistory, fetchBillingReport } from "../components/api/api";

import { Result, Button, Popover } from "antd";
import { FrownFilled } from "@ant-design/icons";
import HelpPopout from "../components/layout/HelpPopout";
import "./ErrorBoundary.css";

function Billing() {
  const [isLoading, setIsLoading] = useState(true);
  const [isTicketsLoading, setIsTicketsLoading] = useState(true);
  const [areaAnalysis, setAreaAnalysis] = useState({});
  const [last30Days, setLast30Days] = useState({})
  const [previous30Days, setPrevious30Days] = useState({})
  const [tickets, setTickets] = useState([]);
  const [unauthorized, setUnauthorized] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const billingData = await fetchBillingReport();

        if (billingData && billingData.area_analysis) {
          const areaAnalysisData = billingData.area_analysis

          const auraLast30Days = areaAnalysisData.aura_tickets_total.daily_area.slice(-30);
          const senseLast30Days = areaAnalysisData.monitoring_tickets_total.daily_area.slice(-30);
          const totalLast30Days = auraLast30Days.concat(senseLast30Days);

          const last30DaysData = {
            aura_tickets_total: {
              daily_area: auraLast30Days,
              area_total: auraLast30Days.reduce((acc, value) => acc + value.total_area, 0),
            },
            monitoring_tickets_total: {
              daily_area: senseLast30Days,
              area_total: senseLast30Days.reduce((acc, value) => acc + value.total_area, 0),
            },
            total_tickets: {
              daily_area: totalLast30Days,
              area_total: totalLast30Days.reduce((acc, value) => acc + value.total_area, 0),
            },
          }

          const auraPrevious30Days = areaAnalysisData.aura_tickets_total.daily_area.slice(-60, -30);
          const sensePrevious30Days = areaAnalysisData.monitoring_tickets_total.daily_area.slice(-60, -30);
          const totalPrevious30Days = auraPrevious30Days.concat(sensePrevious30Days);

          const previous30DaysData = {
            total_tickets: {
              daily_area: totalPrevious30Days,
              area_total: totalPrevious30Days.reduce((acc, value) => acc + value.total_area, 0),
            },
          }
          setAreaAnalysis(areaAnalysisData);
          setLast30Days(last30DaysData)
          setPrevious30Days(previous30DaysData)
        } else {
          console.error("Billing data structure is unexpected:", billingData);
        }

      } catch (error) {
        if(error.code == 403) setUnauthorized(true);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchTickets = async () => {
      try {
        setIsTicketsLoading(true);
        let allTickets = [];
        let page = 1;
        let result;

        do {
          result = await fetchBillingReportHistory(page);
          const filteredResults = result.results.map((ticket) => {      
            return {
              key: ticket.id,
              service: ticket.service == "sense" ? "Sense" : "Aura",
              ticketId: ticket.id,
              created_by: ticket.created_by ?? "",
              area: ticket.hectares,
              billing: ticket.credit_value,
              createdAt: ticket.created_at,
              formattedDate: dayjs(ticket.created_at).format('DD/MM/YYYY'),
            };
          });
          allTickets = [...allTickets, ...filteredResults];
          page++;
        } while (result.next);

        setTickets(allTickets);
      } catch (error) {
        if(error.code == 403) setUnauthorized(true);
      } finally {
        setIsTicketsLoading(false);
      }
    };

    fetchData();
    fetchTickets();
  }, []);

  const handleBack = () => {
    const currentPath = location.pathname;
    const pathSegments = currentPath.split("/").filter(Boolean);

    if (pathSegments.length > 1) {
      pathSegments.pop();
      const newPath = `/${pathSegments.join("/")}`;
      window.location.href = newPath;
    } else {
      window.location.href = "/";
    }
  };

  if (unauthorized) {
    return (
      <Result
        className="errorBoundary"
        icon={<FrownFilled style={{ color: "#FF4D4F" }} />}
        title="Você não tem permissão para visualizar essa página"
        subTitle="Fale com seu gerente ou entre em contato com o suporte para requisitar o acesso."
        extra={[
          <Button className="buttonCustomWhite" key={"newForm"} onClick={handleBack}>
            Voltar
          </Button>,
          <Popover
            content={<HelpPopout closePopover={() => setOpen(false)} />}
            trigger="click"
            placement="right"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button className="buttonCustom" key={"seeHistory"} onClick={() => setOpen(!open)}>
              Reportar um erro
            </Button>
          </Popover>,
          
        ]}
      />
    );
  }

  if (isLoading) {
    return <LoadingPage customMessages={[
      "Gerando relatórios...",
      "Carregando consultas...",
      "Verificando área das consultas...",
      "Preenchendo gráficos..."
    ]}/>;
  }

  return (
    <div className="billingContainer">
      <div className="billingElementFlexVertical">
        <div>
          <div className="cardTitle">
            Panorama de custos
          </div>
          <div className="billingElementFlexHorizontal">
            <div className="billingElementFlexVertical" style={{width: "80%"}}>
              <div className="equalHorizontalCards">
                <AuraChart data={last30Days.aura_tickets_total}/>
                <SenseChart data={last30Days.monitoring_tickets_total}/>
                <TotalMonitoring
                  last30Days={last30Days.total_tickets}
                  previous30Days={previous30Days.total_tickets}
                />
              </div>
              <ConsultedHectaresGraph
                data={areaAnalysis}
                tickets={tickets}
                isLoading={isTicketsLoading}
              />
            </div>
            <MonthlyCostChart
              auraDaily={areaAnalysis.aura_tickets_total.daily_area}
              senseDaily={areaAnalysis.monitoring_tickets_total.daily_area}
            />
          </div>
        </div>
        <div>
          <div className="cardTitle">
            Histórico de Consultas
          </div>
          <BillingTable ticketsData={tickets} isLoading={isTicketsLoading}/>
        </div>
      </div>
    </div>
  );
}

export default Billing;
