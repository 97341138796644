import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { Spin } from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import getScoreInfo from "./functions/GetScoreInfo";

const FarmInfoRiskChart = ({ value, status, totalClassifications }) => {
    const [loading, setIsLoading] = useState(status === "calculating");
    const { quality, color } = getScoreInfo(value);

    useEffect(() => {
        setIsLoading(status === "calculating");
    }, [value]);

    const chartOptions = {
        chart: {
            type: "radialBar", sparkline: {
                enabled: true,
            }, offsetY: -6,
        }, plotOptions: {
            radialBar: {
                startAngle: -135, endAngle: 135, hollow: {
                    margin: 4, size: "64%", background: "white",
                }, track: {
                    background: "#e7e7e7", strokeWidth: "97%", margin: 5,
                }, dataLabels: {
                    name: {
                        show: true, fontSize: "14px", offsetY: 48, color: color,
                    }, value: {
                        formatter: function (val) {
                            return parseInt(val * 10);
                        },
                        color: color,
                        fontWeight: "bold",
                        fontSize: "36px",
                        fontFamily: "Helvetica, Arial, sans-serif",
                        offsetY: -8,
                        show: true,
                    },
                },
            },
        }, fill: {
            colors: [color],
        }, stroke: {
            lineCap: "round", width: 2, colors: ["#FFFFFF"],
        }, labels: [quality],
    };

    const scaledValue = value / 10;
    if (status === "error" || value === 0) {
        return (<div className="farmInfoRiskChart" style={{ color: "grey", fontWeight: 600 }}>N/A</div>)
    }
    else if (loading) {
        return <Spin
            className="farmInfoRiskChart"
            indicator={<LoadingOutlined
                style={{
                    fontSize: 150, color: "#B64CFF",
                }}
                spin
            />}
        />
    } else if (totalClassifications === 0) {
        return <div className="farmInfoRiskChart" style={{ color: "grey", fontWeight: 600 }}>N/A</div>
    }
    return (<div className="farmInfoRiskChart">
        <ReactApexChart
            options={chartOptions}
            series={[scaledValue]}
            type="radialBar"
            height={170}
        />
    </div>);
};

export default FarmInfoRiskChart;
