import L from "leaflet";
import "leaflet.pattern"; // Ensure leaflet.pattern is installed and properly imported

// Define the KML area pattern with a single triangle and proper background color
const kmlAreaPattern = new L.Pattern({
  width: 30,
  height: 30,
  patternUnits: "userSpaceOnUse",
  patternContentUnits: "userSpaceOnUse",
});

// Add a background rectangle
kmlAreaPattern.addShape(
  new L.PatternRect({
    x: 0,
    y: 0,
    width: 30,
    height: 30,
    fill: true,
    fillColor: "#F5222D",
    fillOpacity: 0.25,
    stroke: false,
  })
);

// Add a single triangle on top of the background
kmlAreaPattern.addShape(
  new L.PatternPath({
    d: "M15 5L25 25L5 25Z", // Single triangle path within the 30x30 pattern area
    fill: true,
    fillColor: "#000",
    fillOpacity: 1,
    stroke: false,
  })
);

// Define the ESG area pattern with straight line fill
const esgAreaPattern = new L.Pattern({
  width: 25,
  height: 10,
  patternUnits: "userSpaceOnUse",
  patternContentUnits: "userSpaceOnUse",
});

// Add a background rectangle with a bright blue color
esgAreaPattern.addShape(
  new L.PatternRect({
    x: 0,
    y: 0,
    width: 25,
    height: 10,
    fill: true,
    fillColor: "#FDBF6F",
    fillOpacity: 1,
    stroke: false,
  })
);

// Add straight line fill pattern with the specified separations
esgAreaPattern.addShape(
  new L.PatternPath({
    d: "M0 5L25 5",
    fill: false,
    stroke: true,
    color: "#000",
    weight: 1,
    dashArray: "1, 5",
  })
);

// Define the styles for different polygons
const polygonStylePatterns = {
  1: { color: "#ff9959", weight: 1, opacity: 0.75, fillOpacity: 1 },
  5: { color: "#af8283", weight: 1, opacity: 0.75, fillOpacity: 1 }, // area_imovel_snci
  6: { color: "#f8c4b5", weight: 1, opacity: 0.75, fillOpacity: 1 }, // area_pousio
  7: { color: "#ef5c6c", weight: 1, opacity: 0.75, fillOpacity: 1 }, // area_topo_morro
  8: { color: "#4f5f14", weight: 1, opacity: 0.75, fillOpacity: 1 }, // banhado
  9: { color: "#64e36f", weight: 1, opacity: 0.75, fillOpacity: 1 }, // borda_chapada
  10: { color: "#480d94", weight: 1, opacity: 0.75, fillOpacity: 1 }, // hidrografia
  11: { color: "#c8e52a", weight: 1, opacity: 0.75, fillOpacity: 1 }, // manguezal
  12: { color: "#0aa5b6", weight: 1, opacity: 0.75, fillOpacity: 1 }, // nascente_olho_dagua
  13: { color: "#fdbf6f", weight: 1, opacity: 0.75, fillOpacity: 1 }, // reserva_legal
  14: { color: "#27b075", weight: 1, opacity: 0.75, fillOpacity: 1 }, // restinga
  15: { color: "#d9a773", weight: 1, opacity: 0.75, fillOpacity: 1 }, // servidao_administrativa
  16: { color: "#c7e75d", weight: 1, opacity: 0.75, fillOpacity: 1 }, // uso_restrito
  17: { color: "#72af80", weight: 1, opacity: 0.75, fillOpacity: 1 }, // vegetacao_nativa
  18: { color: "#b76559", weight: 1, opacity: 0.75, fillOpacity: 1 }, // vereda
  19: { color: "#a4af9f", weight: 1, opacity: 0.75, fillOpacity: 1 }, // desmatamento_prodes
  20: { color: "#77afa3", weight: 1, opacity: 0.75, fillOpacity: 1 }, // icmbio
  21: { color: "#989baf", weight: 1, opacity: 0.75, fillOpacity: 1 }, // ibama
  22: { color: "#af6465", weight: 1, opacity: 0.75, fillOpacity: 1 },// assentamento

  carArea: {
    color: "#1890FF",
    fillColor: "#01355D",
    border: "2px dashed #01355D",
    fillOpacity: 0.4,
    weight: 2,
    opacity: 1,
    dashArray: "4, 4",
  },
  kmlArea: {
    fillColor: "#D8AECE",
    fillOpacity: 1,
    weight: 0,
    opacity: 0.75,
  },
  kmlAreaPink: {
    color: "#000",
    fillColor: "#D8AECE",
    fillOpacity: 1,
    weight: 1,
    opacity: 0.75,
  },
  esgArea: {
    color: "#000",
    fillColor: "#52C41A",
    fillOpacity: 1,
    weight: 1,
    opacity: 0.75,
  },
  sojaArea: {
    color: "#000",
    fillColor: "#E5B636",
    fillOpacity: 1,
    weight: 1,
    opacity: 0.75,
  },
  milhoArea: {
    color: "#000",
    fillColor: "#F39345",
    fillOpacity: 1,
    weight: 1,
    opacity: 0.75,
  },
  arrozArea: {
    color: "#000",
    fillColor: "#DE6CDA",
    fillOpacity: 1,
    weight: 1,
    opacity: 0.75,
  },
  outroArea: {
    color: "#000",
    fillColor: "#BDBDBD",
    fillOpacity: 1,
    weight: 1,
    opacity: 0.75,
  },
};

export { polygonStylePatterns };
