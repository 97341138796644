import React from "react";
import { Typography, Divider, Tooltip } from "antd";
import "./PlantingWindow.css";
import {
  ScanOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  FileExcelFilled,
  InfoCircleOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';

const { Text } = Typography;

function accordingToZarc(dateZarcStart, dateZarcEnd, dateSowingStart, dateSowingEnd) {
  const zarcStart = dayjs(dateZarcStart);
  const zarcEnd = dayjs(dateZarcEnd);
  const sowingStart = dayjs(dateSowingStart);
  const sowingEnd = dayjs(dateSowingEnd);

  return sowingStart >= zarcStart && sowingEnd <= zarcEnd;
}

function zarcEstimations(dateZarcStart, dateZarcEnd) {
  if (dateZarcStart && dateZarcEnd)
    return `${dayjs(dateZarcStart).format('DD/MM/YYYY')} - ${dayjs(dateZarcEnd).format('DD/MM/YYYY')}`;

  return "Data inválida";
}

function zarcTag(dateZarcStart, dateZarcEnd, dateSowingStart, dateSowingEnd) {
  if (!dateSowingStart || !dateSowingEnd) 
    return <div style={{ display: "flex", gap: "4px" }}>
      <ScanOutlined style={{ color: "#FFA940" }} />
      <Text className="textInfo">
        Plantio não detectado
      </Text>
    </div>

  if (!dateZarcStart || !dateZarcEnd)
    return <div style={{ display: "flex", gap: "4px" }}>
      <FileExcelFilled style={{ color: "#FF4D4F" }} />
      <Text className="textInfo">
        ZARC não encontrado
      </Text>
    </div>

    if (accordingToZarc(dateZarcStart, dateZarcEnd, dateSowingStart, dateSowingEnd))
      return <div style={{ display: "flex", gap: "4px" }}>
        <CheckCircleFilled style={{ color: "#52C41A" }} />
        <Text className="textInfo">
          Plantando conforme ZARC
        </Text>
      </div>

    return <div style={{ display: "flex", gap: "4px" }}>
      <CloseCircleFilled style={{ color: "#FF4D4F" }} />
      <Text className="textInfo">
        Plantando fora do ZARC
      </Text>
    </div>
}

const PlantingWindow = ({
  dateZarcStart,
  dateZarcEnd,
  dateSowingStart,
  dateSowingEnd,
  dateHarvestStart,
  dateHarvestEnd,
}) => (
  <div className="plantingWindowContainer">
    <h2 className="plantingWindowTitle">Janela da safra</h2>
    <Divider className="dividerCustom" />
    <div className="elementFlexVertical">
      <div className="infoSection">
        <img src="/icons/today.svg" alt="Calendar" className="iconLarge" />
        <div>
          <Text className="textInfoTitle">
            Janela estipulada pelo ZARC
            <Tooltip title={zarcEstimations(dateZarcStart, dateZarcEnd)}>
              <InfoCircleOutlined className="infoIcon" style={{marginLeft: 4}} />
            </Tooltip>
          </Text>
          <br />
          {zarcTag(dateZarcStart, dateZarcEnd, dateSowingStart, dateSowingEnd)}
        </div>
      </div>
      <div className="infoSection">
        <img src="/icons/water.svg" alt="Menu" className="iconLarge" />
        <div>
          <Text className="textInfoTitle">Data estimada de plantio</Text>
          <br />
          <Text className="textInfo">
            {(dateSowingStart !== "" && dateSowingEnd !== "") ?
              `${dayjs(dateSowingStart).format('DD/MM/YYYY')} - ${dayjs(dateSowingEnd).format('DD/MM/YYYY')}` : "Não disponível"}
          </Text>
        </div>
      </div>

      <div className="infoSection">
        <img
          src="/icons/inventory.svg"
          alt="Inbox"
          className="iconLarge"
        />
        <div>
          <Text className="textInfoTitle">Data estimada de colheita</Text>
          <br />
          <Text className="textInfo">
            {(dateHarvestStart !== "" && dateHarvestEnd !== "") ?
              `${dayjs(dateHarvestStart).format('DD/MM/YYYY')} - ${dayjs(dateHarvestEnd).format('DD/MM/YYYY')}` : "Não disponível"}
          </Text>
        </div>
      </div>
    </div>
  </div>
);

export default PlantingWindow;
