import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Divider } from 'antd';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(isLeapYear);

import "./MonthlyCost.css";

const MonthlyCostChart = ({ auraDaily, senseDaily, date }) => {
    const calculateMonthlyTotal = (data) => {
        const currentMonth = dayjs(date).month();
        const currentYear = dayjs(date).year();
        return data
            .filter(item => dayjs(item.date).month() === currentMonth && dayjs(item.date).year() === currentYear)
            .reduce((sum, item) => sum + item.total_area, 0);
    };

    const auraTotal = calculateMonthlyTotal(auraDaily);
    const senseTotal = calculateMonthlyTotal(senseDaily);

    const series = [Math.floor(auraTotal), Math.floor(senseTotal)];
    const total = auraTotal + senseTotal;

    const day = dayjs(date).date();
    const monthDays = dayjs(date).daysInMonth();

    const options = {
        chart: {
            type: 'donut',
        },
        labels: ['Aura', 'Sense'],
        colors: ['#9236D9', '#1890FF'],
        legend: {
            show: false,
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: false,
                        },
                        total: {
                            show: true,
                            formatter: function () {
                                return `${day}/${monthDays} dias`;
                            }
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        }
    };

    return (
        <div className="monthlyCostCard">
            <div className='monthlyCostChartTitle'>
                Custos mensais totais
            </div>
            <div className="billingCostChartContent">
                <div className='monthlyCostChartSubtitle'>
                    Valor total mensal
                </div>
                <div className='billingCostChartContainer'>
                    <ReactApexChart 
                        className="monthlyCostChart"
                        options={options}
                        series={series}
                        type="donut"
                    />
                    <div className='monthlyCostLegend'>
                        <div className='monthlyCostLegendLine'>
                            <div className='monthlyCostLegendDot'>
                                <div
                                    style={{
                                        height: "8px",
                                        width: "8px",
                                        borderRadius: "50%",
                                        backgroundColor: "#9236D9",
                                    }}
                                ></div>
                                Aura
                            </div>
                            <Divider type="vertical" />
                            <div style={{minWidth: "60px", textAlign: "center"}}>
                                {(100 * auraTotal / total).toFixed(2)}%
                            </div>
                            <div style={{minWidth: "68px", textAlign: "right"}}>
                                {Math.floor(auraTotal).toLocaleString('pt-BR')}
                            </div>
                        </div>
                        <div className='monthlyCostLegendLine'>
                            <div className='monthlyCostLegendDot'>
                                <div
                                    style={{
                                        height: "8px",
                                        width: "8px",
                                        borderRadius: "50%",
                                        backgroundColor: "#1890FF",
                                    }}
                                ></div>
                                Sense
                            </div>
                            <Divider type="vertical" />
                            <div style={{minWidth: "60px", textAlign: "center"}}>
                                {(100 * senseTotal / total).toFixed(2)}%
                            </div>
                            <div style={{minWidth: "68px", textAlign: "right"}}>
                                {Math.floor(senseTotal).toLocaleString('pt-BR')}
                            </div>
                        </div>
                        <div className='monthlyCostLegendLine'>
                            <div className='monthlyCostLegendDot'>
                                <div
                                    style={{
                                        height: "8px",
                                        width: "8px",
                                        borderRadius: "50%",
                                        backgroundColor: "#2F54EB",
                                    }}
                                ></div>
                                Total mensal
                            </div>
                            <Divider type="vertical" />
                            <div style={{minWidth: "60px", textAlign: "center"}}/>
                            <div style={{minWidth: "68px", textAlign: "right"}}>
                                {Math.floor(total).toLocaleString('pt-BR')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonthlyCostChart;
