import React from "react";

const statusInfoMap = {
  monitoring: { color: "#1890FF", label: "Monitorado" },
  approved: { color: "#52C41A", label: "Aprovado" },
  disapproved: { color: "#FF4D4F", label: "Reprovado" },
  inAnalysis: { color: "#FAAD14", label: "Em análise" },
  error: { color: "#FF4D4F", label: "Erro" },
  calculating: { color: "#B64CFF", label: "Calculando" }
};

const getStatus = (ticket) => {
  let status;

  if (
    ticket.risk_calculation_status == 'success'
    && ticket.sense_status == false
    && ticket.total_classifications == 0
  ) {
    status = "inAnalysis";

    return {
      status,
      color: statusInfoMap[status].color,
      label: statusInfoMap[status].label
    };
  }



  if (ticket.risk_calculation_status !== 'success' && ticket.risk_classification_records_count < 5 && ticket.sense_status == false) {
    status = 'calculating'

    return {
      status,
      color: statusInfoMap[status].color,
      label: statusInfoMap[status].label
    };
  }


  if (ticket.sense_status) {
    status = "monitoring";
  } else if (ticket.consultation_status === 'A') {
    status = "approved";
  } else if (ticket.consultation_status === 'R') {
    status = "disapproved";
  } else if (ticket.risk_calculation_status === "success") {
    status = "inAnalysis";
  } else if (ticket.risk_calculation_status === "error") {
    status = "error";
  } else {
    status = "calculating";
  }

  return {
    status,
    color: statusInfoMap[status].color,
    label: statusInfoMap[status].label
  };
};


export default getStatus;
