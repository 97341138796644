import React, { useRef, useState } from 'react';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, DatePicker, Spin } from 'antd';
import moment from 'moment';

import "./BillingTable.css";

const BillingTable = ({ticketsData, isLoading}) => {
  const [searchUser, setSearchUser] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchUser(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={"Buscar"}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 120 }}
          >
            Buscar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={{ width: 40 }}
          >
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) => {
      return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const columns = [
    {
      title: 'Serviço',
      dataIndex: 'service',
      sorter: {
        compare: (a, b) => a.service.localeCompare(b.service),
        multiple: 6,
      },
      filters: [
        { text: 'Aura', value: 'Aura' },
        { text: 'Sense', value: 'Sense' },
      ],
      onFilter: (value, record) => record.service === value,
    },
    {
      title: 'ID do Ticket',
      dataIndex: 'ticketId',
      ...getColumnSearchProps('ticketId'),
      sorter: {
        compare: (a, b) => a.ticketId.localeCompare(b.ticketId),
        multiple: 5,
      },
    },
    {
      title: 'Usuário',
      dataIndex: 'created_by',
      ...getColumnSearchProps('created_by'),
      sorter: {
        compare: (a, b) => a.created_by.localeCompare(b.created_by),
        multiple: 4,
      },
    },
    {
      title: 'Hectares',
      dataIndex: 'area',
      render: (area) => area.toFixed(2),
      sorter: {
        compare: (a, b) => a.area - b.area,
        multiple: 3,
      },
    },
    {
      title: 'Créditos',
      dataIndex: 'billing',
      render: (cost) => cost.toFixed(2),
      sorter: {
        compare: (a, b) => a.billing - b.billing,
        multiple: 2,
      },
    },
    {
      title: 'Data da Consulta',
      dataIndex: 'formattedDate',
      sorter: {
        compare: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        multiple: 1,
      },
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    // console.log('params', pagination, filters, sorter, extra);
  };

  return (
    <Table
      className='billingTable'
      columns={columns}
      dataSource={ticketsData}
      onChange={onChange}
      pagination={false}
      scroll={{ y: 400 }}
      sticky
      loading={{
        spinning: isLoading,
        indicator: <Spin
          indicator={<LoadingOutlined style={{fontSize: 40}}/>}
          style={{
            color: "#B64CFF",
          }}
        />
      }}
    />
  );
}

export default BillingTable;
