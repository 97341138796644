import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import DashboardHeader from "../components/dashboard/Header";
import FarmInfoSense from "../components/dashboard/FarmInfoSense";
import MapboxWithFilters from "../components/dashboard/MapboxWithFilters";
import TemperatureChartTwoWeeks from "../components/dashboard/TemperatureChartTwoWeeks";
import WindSpeedChartTwoWeeks from "../components/dashboard/WindSpeedChartTwoWeeks";
import PlantingWindow from "../components/dashboard/PlantingWindow";
import RainfallAverage from "../components/dashboard/RainfallAverage";
import GrowthCurves from "../components/dashboard/GrowthCurves";
import Notifications from "../components/dashboard/Notifications";
import AtypicalEvents from "../components/dashboard/AtypicalEvents";
import PrecipitationChart from "../components/dashboard/PrecipitationChart";
import { fetchTicketData, fetchSenseTicketData } from "../components/api/api";
import formatDate from "../components/dashboard/functions/formatDate";
import generateAlerts from "../components/dashboard/functions/GenerateAlerts";
import ReportDateSelector from "../components/dashboard/ReportDateSelector";
import ESGCards from "../components/dashboard/ESGCards";
import calculateSum from "../components/dashboard/functions/CalculateSum";
import senseTempWindspeed from "../components/dashboard/functions/SenseTempWindspeed";

import "./Reports.css";
import LoadingPage from "../components/dashboard/LoadingPage";
import getStatus from "../components/dashboard/Status";

function SenseReport() {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [senseParamsData, setSenseParamsData] = useState(null);
  const [realAlerts, setRealAlerts] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");

  const [previousPlantHealth, setPreviousPlantHealth] = useState("");
  const [previousRainfallAvg, setPreviousRainfallAvg] = useState("");

  const [dateZarcStart, setDateZarcStart] = useState("");
  const [dateZarcEnd, setDateZarcEnd] = useState("");
  const [dateSowingStart, setDateSowingStart] = useState("");
  const [dateSowingEnd, setDateSowingEnd] = useState("");
  const [dateHarvestStart, setDateHarvestStart] = useState("");
  const [dateHarvestEnd, setDateHarvestEnd] = useState("");
  const [status, setStatus] = useState(null);
  const [retry, setRetry] = useState(0);
  const [coords, setCoords] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      Promise.all([fetchTicketData(id), fetchSenseTicketData(id)])
        .then(([ticketData, senseParams]) => {
          setStatus('success');
          setData(ticketData);
          setFormattedDate(formatDate(ticketData.created_at));
          setSenseParamsData(senseParams);
          setPreviousPlantHealth(senseParams.previous_report_plant_health);
          setPreviousRainfallAvg(senseParams.previous_report_rainfall_sum);
          setRealAlerts(generateAlerts(senseParams.notifications, ticketData.sicar_overlaps));

          setDateZarcStart(senseParams.zarc_estimations?.zarc_estimation_start ?? "");
          setDateZarcEnd(senseParams.zarc_estimations?.zarc_estimation_end ?? "");

          setDateSowingStart(senseParams.sowing_estimations?.estimated_sowing_start ?? "");
          setDateSowingEnd(senseParams.sowing_estimations?.estimated_sowing_end ?? "");
          
          setDateHarvestStart(senseParams.harvest_estimations?.estimated_harvest_start ?? "");
          setDateHarvestEnd(senseParams.harvest_estimations?.estimated_harvest_end ?? "");

          setCoords([ticketData.center_coords.lat, ticketData.center_coords.long]);

          setIsLoading(false);
        })
        .catch((error) => {
          setRetry(prevRetry => prevRetry + 1);
          if (error.response && error.response.status === 404) {
            setStatus('error');
          } else {
            setStatus('error');
          }
        });
    }

    if(data == null && retry == 0) {
      fetchData();
    } else if(status != 'success') {
      const timeout = setTimeout(() => {
        fetchData()
      }, 10000)

      return () => clearTimeout(timeout);
    }

  }, [id, retry]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) {
    return <LoadingPage />;
  }

  let { temperatures, windSpeeds } = senseTempWindspeed(
    senseParamsData ? senseParamsData.weather_info : []
  );

  function updateReportData(newData) {
    setSenseParamsData({
      ...senseParamsData,
      weather_info: newData.weather_info,
      zarc_estimated_date: newData.zarc_estimated_date,
      notifications: newData.notifications,
      plant_health: newData.plant_health,
    });
    setFormattedDate(newData.report_date);
    if (newData && newData.previous_report_plant_health) {
      setPreviousPlantHealth(
        newData.previous_report_plant_health.avg_value || ""
      );
    } else {
      setPreviousPlantHealth("");
    }

    setPreviousRainfallAvg(newData.previous_report_rainfall_avg);
  }

  return (
    <div className="reportContainer">

      { data ? (
        <div className="elementFlexVertical">
          <div className="elementFlexHorizontal" style={{ minHeight: "64px" }}>
            <DashboardHeader ticket={data} />
            <ReportDateSelector
              KMLID={data?.id}
              onDateChange={updateReportData}
            />
          </div>

          <div className="elementFlexHorizontal" style={{ minHeight: "440px" }}>
            <div
              className="elementFlexVertical"
              style={{ flex: 3, maxWidth: "432px" }}
            >
              <div className="elementFlexVertical" style={{ flex: 3 }}>
                <FarmInfoSense
                  farmerName={data.farm_data?.farmer?.farmer_name ?? ""}
                  farmName={data.farm_data.farm_name}
                  farmLocation={
                    data.farm_data.kml_document.kml_polygon.municipality
                  }
                  cropType={data.farm_data.crop_type}
                  hectares={data.land_size.hectares}
                  status={getStatus(data)}
                  ticketNumber={data?.id}
                  subscriber={data.subwriter}
                  date={formattedDate}
                  mapImg={data.farm_data.kml_document.kml_polygon.map_html}
                  riskScore={data.risk_score}
                />
              </div>
              <div className="elementFlexVertical" style={{ flex: 1 }}>
                <Notifications notifications={realAlerts} />
              </div>
            </div>

            <div className="reportElementCard" style={{ flex: 6 }}>
              <h2
                className="plantingWindowTitle"
                style={{ margin: "0px 0px 32px 12px" }}
              >
                Monitoramento
              </h2>
              <div className="elementFlexHorizontal">
                <div className="elementFlexVertical" style={{ flex: 1 }}>
                  <AtypicalEvents
                    notifications={senseParamsData.atypical_events}
                    esg_detections={data.sicar_overlaps}
                  />
                  <PlantingWindow
                    dateZarcStart={dateZarcStart}
                    dateZarcEnd={dateZarcEnd}
                    dateSowingStart={dateSowingStart}
                    dateSowingEnd={dateSowingEnd}
                    dateHarvestStart={dateHarvestStart}
                    dateHarvestEnd={dateHarvestEnd}
                  />
                </div>

                <div className="elementFlexVertical" style={{ flex: 3 }}>
                  <PrecipitationChart
                    datesArray={senseParamsData.weather_info.dates}
                    valuesArray={senseParamsData.weather_info.precipitation}
                  />
                  <div className="elementFlexHorizontal" style={{ flex: 1 }}>
                    <RainfallAverage
                      last15Days={calculateSum(
                        senseParamsData.weather_info.precipitation
                      )}
                      lastReport={parseFloat(previousRainfallAvg)}
                    />
                    <GrowthCurves
                      plantHealth={isNaN(parseFloat(senseParamsData.plant_health?.avg_ndvi)) ? null : parseFloat(senseParamsData.plant_health?.avg_ndvi)}
                      plantHealthLastReport={isNaN(parseFloat(previousPlantHealth?.avg_ndvi)) ? null : parseFloat(previousPlantHealth?.avg_ndvi)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="elementFlexHorizontal">
            <ESGCards
              data={data.risk_classification_records}
              isLoading={data.risk_score ? data.risk_score : null}
              sicar_overlaps={data.sicar_overlaps}
            />
          </div>

          <div className="elementFlexHorizontal">
            <TemperatureChartTwoWeeks data={temperatures} />
            <WindSpeedChartTwoWeeks data={windSpeeds} />
          </div>

          <div className="elementFlexHorizontal" style={{ minHeight: "480px" }}>
            <MapboxWithFilters id={data?.id} coords={coords} style={{ height: "inherit" }} />
          </div>
        </div>
      ) : (
        <div>Carregando...</div>
      )}
    </div>
  );
}

export default SenseReport;
