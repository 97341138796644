import React from "react";
import { Typography, Divider } from "antd";
import { RiseOutlined, FileSyncOutlined } from "@ant-design/icons";
import "./PlantingWindow.css";

const { Text } = Typography;

const GrowthCurves = ({ plantHealth, plantHealthLastReport }) => (
  <div className="plantingWindowContainer">
    <h2 className="plantingWindowTitle">Curvas de crescimento</h2>
    <Divider className="dividerCustom" />
    <div className="elementFlexVertical">
      <div className="infoSection">
        <RiseOutlined className="iconLarge" />
        <div>
          <Text className="textInfoTitle">Plant Health</Text>
          <br />
          <Text className="textInfo">
            {plantHealth != null ? plantHealth.toFixed(2) : "Não disponível"}
          </Text>
        </div>
      </div>

      <div className="infoSection">
        <FileSyncOutlined className="iconLarge" />
        <div>
          <Text className="textInfoTitle">Último relatório</Text>
          <br />
          <Text className="textInfo">
            {plantHealthLastReport ? plantHealthLastReport : "Não disponível"}
          </Text>
        </div>
      </div>
    </div>
  </div>
);

export default GrowthCurves;
