function getScoreInfo(score) {
  if (score == 0) {
    return { quality: "N/A", color: "grey", value: score };
  } else if (score >= 0 && score <= 150) {
    return { quality: "Crítico", color: "#FF0026", value: score };
  } else if (score <= 300) {
    return { quality: "Ruim", color: "#FF3900", value: score };
  } else if (score <= 500) {
    return { quality: "Moderado", color: "#FFA800", value: score };
  } else if (score <= 700) {
    return { quality: "Bom", color: "#5ED441", value: score };
  } else if (score <= 800) {
    return { quality: "Ótimo", color: "#16BB00", value: score };
  } else {
    return { quality: "Excelente", color: "#009200", value: score };
  }
}

export default getScoreInfo;
