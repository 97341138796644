import React from 'react';

import "./Billing.css";
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

const TotalMonitoring = ({ last30Days, previous30Days }) => {
    let dailyVariation = 0;
    let monthlyVariation = 0;
    let totalLast30Days = 0;
    let totalPrevious30Days = 0;

    if (last30Days.daily_area.length > 0) {
        totalLast30Days = last30Days.area_total
        totalPrevious30Days = previous30Days.area_total

        monthlyVariation = totalLast30Days - totalPrevious30Days;

        const lastTwoDays = last30Days.daily_area.slice(-2);
        if (lastTwoDays.length === 2) {
            dailyVariation = lastTwoDays[1].total_area - lastTwoDays[0].total_area;
        }
        if (lastTwoDays.length === 1) {
            dailyVariation = lastTwoDays[1].total_area;
        }
    }

    return (
        <div className='billingChartCard'>
            <div className='billingChartTitle'>Total de hectares em monitoramento</div>
            <div className='billingChartNumber'>
                {Math.floor(totalLast30Days).toLocaleString('pt-BR')} Hec
            </div>
            <div className='billingVariationContent'>
                <div className='billingVariationText'>
                    <>
                        Variação diária: {Math.floor(Math.abs(dailyVariation)).toLocaleString('pt-BR')} Hectares
                    </>
                    {dailyVariation >= 0 ?
                        <CaretUpOutlined style={{color: "#52C41A"}}/> :
                        <CaretDownOutlined style={{color: "#FF4D4F"}}/>}
                </div>
                <div className='billingVariationText'>
                    <>
                        Variação mensal: {Math.floor(Math.abs(monthlyVariation)).toLocaleString('pt-BR')} Hectares
                    </>
                    {monthlyVariation >= 0 ?
                        <CaretUpOutlined style={{color: "#52C41A"}}/> :
                        <CaretDownOutlined style={{color: "#FF4D4F"}}/>}
                </div>
            </div>
        </div>
    );
};

export default TotalMonitoring;
