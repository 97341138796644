import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import ListCard from "./ListCard";
import { LoadingOutlined, RedoOutlined } from "@ant-design/icons";
import { Select, Input, Spin, Card } from "antd";
import LoadingPage from "../components/dashboard/LoadingPage";
import "./TicketListing.css"

import "./TicketListing.css";

const { Search } = Input;

function TicketListing({ fetchTickets }) {
  const location = useLocation();
  const basePath = location.pathname;

  // let useCache = basePath === sessionStorage.getItem("cachedHistory");
  let useCache = basePath === 'false';

  const [firstTime, setFirstTime] = useState(true);

  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [index, setIndex] = useState(2);

  const [searchTerm, setSearchTerm] = useState("");
  const [displayStatus, setDisplayStatus] = useState(['A', 'P']);
  const [displayOrder, setDisplayOrder] = useState("-created_at");
  const [isLoadingPage, setIsLoadingPage] = useState(null);
  const [isLoadingFetch, setIsLoadingFetch] = useState(null);

  const getData = useCallback(async (
    term = searchTerm, status = displayStatus, order = displayOrder, firstLoad = firstTime
  ) => {
    if (useCache && firstLoad) {
      setItems(JSON.parse(sessionStorage.getItem("cachedTickets")));
      setHasMore(sessionStorage.getItem("cachedTicketsHasMore") === "true");
      setIndex(parseInt(sessionStorage.getItem("cachedTicketsPage")));
      setIsLoadingPage(false);
      setIsLoadingFetch(false);
      setFirstTime(false);

      const savedScrollPosition = sessionStorage.getItem('scrollPosition');
      if (savedScrollPosition) {
        setTimeout(() => {
          window.scrollTo({
            top: parseFloat(savedScrollPosition),
            behavior: 'smooth'
          });
          localStorage.removeItem('scrollPosition');
          sessionStorage.removeItem('scrollPosition');
        }, 200);
      }

      return;
    }

    const response = await fetchTickets(1, term, status, order);
    setItems(response.results);
    setHasMore(!!response.next);
    setIsLoadingPage(false);
    setIsLoadingFetch(false);
    setFirstTime(false);
  }, []);

  useEffect(() => {
    if (items.length === 0) return;
    sessionStorage.setItem("cachedHistory", basePath);
    sessionStorage.setItem("cachedTickets", JSON.stringify(items));
    sessionStorage.setItem("cachedTicketsPage", index);
    sessionStorage.setItem("cachedTicketsHasMore", hasMore);
  }, [items]);

  useEffect(() => {
    setIsLoadingPage(true);
  }, []);

  useEffect(() => {
    setIsLoadingFetch(true);
    setIndex(2);
    setItems([]);
    getData(searchTerm, displayStatus, displayOrder, firstTime);
  }, [searchTerm, displayStatus, displayOrder])

  const fetchMoreData = useCallback(async () => {
    const response = await fetchTickets(index, searchTerm, displayStatus, displayOrder);
    const newItems = response.results.filter(
      (newItem) => !items.some((item) => item.id === newItem.id)
    );

    setItems((prevItems) => [...prevItems, ...newItems]);
    setHasMore(!!response.next);
    setIndex((prevIndex) => prevIndex + 1);
  }, [index, searchTerm, displayStatus, displayOrder, items]);

  const handleRefresh = useCallback(() => {
    setSearchTerm("");
    setDisplayStatus(['A', 'P']);
    setDisplayOrder("-created_at");
  }, []);

  const handleStatusChange = useCallback((ticketId, newStatus) => {
    const updatedTickets = items.map((ticket) =>
      ticket.id === ticketId
        ? { ...ticket, consultation_status: newStatus }
        : ticket
    );
    setItems(updatedTickets);
  }, [items]);

  const handleSendToSense = useCallback((ticketId) => {
    const updatedTickets = items.map((ticket) =>
      ticket.id === ticketId ? { ...ticket, sense_status: true } : ticket
    );
    setItems(updatedTickets);
  }, [items]);

  if (isLoadingPage) {
    return <LoadingPage customMessages={["Carregando"]} />;
  }

  return (
    <div>
      <div className="ticketListingBar">
        <RedoOutlined
          className="refreshTicketListing"
          onClick={handleRefresh}
        />
        <Search onSearch={setSearchTerm} allowClear size="large" />
        <div className="selectorBox">
          Exibir:
          <Select
            mode="multiple"
            style={{ width: "240px", height: "48px", overflow: "clip" }}
            placeholder="Selecione"
            value={displayStatus}
            onChange={setDisplayStatus}
            options={[
              { value: "A", label: "Aprovado" },
              { value: "P", label: "Em Análise" },
              { value: "R", label: "Reprovado" },
            ]}
          />
        </div>
        <div className="selectorBox">
          Ordem de exibição:
          <Select
            style={{ minWidth: "184px", height: "48px" }}
            placeholder="Selecione"
            value={displayOrder}
            onChange={setDisplayOrder}
            options={[
              { value: "-created_at", label: "Mais recentes" },
              { value: "created_at", label: "Mais antigos" },
              { value: "-risk_score", label: "Maior score de risco" },
              { value: "risk_score", label: "Menor score de risco" },
            ]}
          />
        </div>
      </div>
      {isLoadingFetch ? (
        <InfiniteScroll
          style={{ overflow: "unset" }}
          dataLength={5}
        >
          {Array.from({ length: 5 }, (_, index) => (
            <Card
              key={index}
              className="listCardLoadingSkeleton"
              style={{
                paddingLeft: "64px",
                paddingRight: "64px",
              }}
              loading={true}
            />
          ))}
        </InfiniteScroll>
      ) : (items.length > 0 ? (
        <InfiniteScroll
          style={{ overflow: "unset" }}
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div style={{ textAlign: "center", padding: "20px 0" }}>
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: "#B64CFF" }}
                    spin
                  />
                }
              />
            </div>
          }
        >
          {items.map((ticket) => (
            <ListCard
              data={ticket}
              key={ticket.id}
              onStatusChange={handleStatusChange}
              onSendToSense={handleSendToSense}
              listUpdateOnDelete={handleRefresh}
            />
          ))}
        </InfiniteScroll>
      ) : (
        <div className="emptyTicketHistory">
          <div>
            <div>Parece que você ainda não fez consultas,</div>
            <div>
              faça um novo envio para que os Tickets comecem a aparecer.
            </div>
          </div>
          <div style={{ display: "flex", gap: "4px" }}>
            Para fazer uma nova consulta clique no botão de
            <span
              style={{
                backgroundColor: "#9236D9",
                borderRadius: "50%",
                height: "36px",
                width: "36px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                top: "-4px",
              }}
            >
              <img
                src={`/assets/icons/icon-kml-upload.svg`}
                style={{ width: "20px", height: "20px" }}
              ></img>
            </span>
            no menu lateral
          </div>
        </div>
      ))}
    </div>
  );
}

export default TicketListing;
