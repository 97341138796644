import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Popover, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./Sidebar.css";
import HelpPopout from "./HelpPopout";
import Cookies from 'js-cookie';

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const role = Cookies.get('role');

  const buttons = [
    {
      name: "Nova consulta",
      path: "/kml-upload",
      icon: "icon-kml-upload.svg",
      specialStyle: "kml-upload-icon",
      showForRoles: ['MU', 'MG', 'DI', 'SW'],
    },
    { name: "Página inicial", path: "/home", icon: "icon-home.svg" ,  showForRoles: ['MU', 'MG', 'DI', 'SW'],},
    { name: "Consultas Aura", path: "/aura", icon: "icon-aura.svg" ,  showForRoles: ['MU', 'MG', 'DI', 'SW'],},
    { name: "Consultas Sense", path: "/sense", icon: "icon-sense.svg" ,  showForRoles: ['MU', 'MG', 'DI', 'SW'],},
    { name: "Mapa Sense+", path: "/sense-plus", icon: "icon-map.svg" ,  showForRoles: ['MU', 'MG', 'DI', 'SW'],},
    { name: "Vision", path: "/vision", icon: "icon-vision.svg" ,  showForRoles: ['MU', 'MG', 'DI', 'SW'],},
    { name: "Central de finanças", path: "/billing", icon: "icon-billing.svg" ,  showForRoles: ['MU', 'MG', 'DI'],},
    { name: "Usuários", path: "/users", icon: "icon-users.svg", showForRoles: ['DI'],},
  ];


  const filteredButtons = role ? buttons.filter(button => button.showForRoles.includes(role)) : [];

  const isActive = (path) => {
    return location.pathname === path || location.pathname.startsWith(path + "/");
  };

  const handleNavigation = (event, path) => {
    if (event.button === 1 || (event.button === 0 && (event.ctrlKey || event.metaKey))) {
      // Wheel click or Ctrl/Cmd + click
      window.open(path, "_blank");
    } else if (event.button === 0) {
      // Left click
      event.preventDefault();
      navigate(path);
    }
  };

  return (
    <div className="SideBar">
      {filteredButtons.map((button) => (
        <Tooltip placement="right" title={button.name} key={button.name}>
          <button
            className={`SideBarButton ${button.specialStyle || ""} ${
              isActive(button.path) ? "active" : ""
            }`}
            onMouseDown={(event) => handleNavigation(event, button.path)}
            onContextMenu={(event) => event.preventDefault()} // Prevent default context menu
          >
            <img
              src={`/assets/icons/${button.icon}`}
              alt={button.name}
              width="20"
              height="20"
            />
          </button>
        </Tooltip>
      ))}
      <Popover
        content={<HelpPopout closePopover={() => setOpen(false)} />}
        trigger="click"
        placement="right"
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Tooltip
          placement="right"
          title="Solicitar Suporte"
          key="Solicitar Suporte"
        >
          <button className={`SideBarButton ${open ? "active" : ""}`}>
            <QuestionCircleOutlined
              style={{ color: "#FFFFFF", fontSize: "20px" }}
            />
          </button>
        </Tooltip>
      </Popover>
    </div>
  );
}

export default Sidebar;
