import ReactApexChart from "react-apexcharts";
import { Card, Typography, Skeleton } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

const { Text } = Typography;

dayjs.locale("pt-br");

const RainfallChart = ({ data }) => {
  if (!data || data.length === 0) {
    return <Skeleton.Image className="flexSkellyNoPadding" active />;

  }

  const series = data.map((yearData) => ({
    name: yearData.year.toString(),
    data: yearData.data.map((monthData) => monthData.value),
  }));

  const categories = data[0].data.map((monthData) =>
    dayjs(monthData.date).format("MMM")
  );

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "90%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {},
    fill: {
      opacity: 0.85,
    },
    colors: ["#D3F261", "#1890FF", "#4ECB74", "#FBD437", "#9236D9", "#F2647B"],
    tooltip: {
      y: {
        formatter: function (val) {
          return val + " mm";
        },
      },
    },
  };

  return (
    <Card
      title={
        <Text
          style={{
            color: "#B64CFF",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "28px",
          }}
        >
          Gráficos de chuva
        </Text>
      }
      style={{
        width: "100%",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={"320px"}
      />
    </Card>
  );
};

export default RainfallChart;
