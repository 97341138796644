import React from "react";
import { Card, Typography, Flex, Spin } from "antd";
import classifyByHarvestYear from "../../components/dashboard/functions/ClassifyByHarvestYear";
import getScoreInfo from "./functions/GetScoreInfo";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import ReactApexChart from "react-apexcharts";

import "./HistoricalParameters.css";

const { Text } = Typography;

const getCurrentYear = () => new Date().getFullYear();

const cardStyle = {
  borderRadius: "6px",
  boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.3)",
  width: "256px",
};

const generateYearsData = (data) => {
  let currentYear = getCurrentYear() - 1;
  let years = [];
  let yearsData = {};

  if (!data || !Array.isArray(data)) {
    return yearsData;
  }

  for (let i = 0; i < 5; i++) {
    years.push(currentYear - i);
  }

  years.forEach((year) => {
    yearsData[year] = null;
  });

  data.forEach((item) => {
    let sowingDateYear = new Date(item.sowing_date).getFullYear();
    let harvestDateYear = new Date(item.harvest_date).getFullYear();

    if (sowingDateYear == harvestDateYear) {
      sowingDateYear = sowingDateYear - 1
    }
    if (yearsData.hasOwnProperty(sowingDateYear)) {
      yearsData[sowingDateYear] = item;
    }
  });

  return yearsData;
};

const getCultureName = (value) => {
  if (value == "soja") return "Soja";
  if (value == "maize") return "Milho";
  if (value == "rice") return "Arroz";
  if (value == "others") return "Outra Cultura";
  return value
}

// const getProductivityRange = (value) => {
//   if (value <= 10) return "0-10 Sa/Ha";
//   if (value <= 15) return "10-15 Sa/Ha";
//   if (value <= 20) return "15-20 Sa/Ha";
//   if (value <= 25) return "20-25 Sa/Ha";
//   if (value <= 30) return "25-30 Sa/Ha";
//   if (value <= 35) return "30-35 Sa/Ha";
//   if (value <= 40) return "35-40 Sa/Ha";
//   if (value <= 45) return "40-45 Sa/Ha";
//   if (value <= 50) return "45-50 Sa/Ha";
//   if (value <= 55) return "50-55 Sa/Ha";
//   if (value <= 60) return "55-60 Sa/Ha";
//   if (value <= 65) return "60-65 Sa/Ha";
//   if (value <= 70) return "65-70 Sa/Ha";
//   if (value <= 75) return "70-75 Sa/Ha";
//   if (value <= 80) return "75-80 Sa/Ha";
//   if (value <= 85) return "80-85 Sa/Ha";
//   if (value <= 90) return "85-90 Sa/Ha";
//   return "+90 Sa/Ha";
// };

const getProductivityRange = (value) => {
  if (value > 55) return "Mais de 55 Sa/Ha";
  if (value > 45) return "Entre 45-55 Sa/Ha";
  if (value > 35) return "Entre 35-45 Sa/Ha";
  if (value > 25) return "Entre 25-35 Sa/Ha";
  if (value > 15) return "Entre 15-25 Sa/Ha";
  return "Menos de 15 Sa/Ha";
}

const HistoricalParameters = ({ data, isCalculating }) => {
  const yearsData = generateYearsData(data);

  return (
    <Card
      title={
        <Text style={{ color: "#B64CFF", fontSize: "24px", fontWeight: "700" }}>
          Parâmetros Históricos
        </Text>
      }
      bordered={true}
      style={{
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.15)",
        width: "100%",
        overflow: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        {Object.keys(yearsData)
          .sort((a, b) => b - a)
          .map((year) => {
            const item = yearsData[year];
            const riskScore = item ? item.risk_score : 0;
            const fenomenoClimatico = year ? classifyByHarvestYear(year) : 0;
            const estimativaDeProdutividade = item
              ? getProductivityRange(item.biomass_productivity)
              : "N/A";

            let { quality, color } = getScoreInfo(riskScore);

            const chartOptions = {
              chart: {
                type: "radialBar",
                sparkline: {
                  enabled: true,
                },
                offsetY: -20,
              },
              plotOptions: {
                radialBar: {
                  startAngle: -135,
                  endAngle: 135,
                  hollow: {
                    margin: 5,
                    size: "64%",
                    background: color,
                  },
                  track: {
                    background: "#e7e7e7",
                    strokeWidth: "97%",
                    margin: 5,
                  },
                  dataLabels: {
                    name: {
                      show: true,
                      fontSize: "16px",
                      offsetY: 68,
                      color: color,
                    },
                    value: {
                      formatter: function (val) {
                        return parseInt(val * 10);
                      },
                      color: "#FFFFFF",
                      background: {
                        enabled: true,
                        foreColor: "#FFFFFF",
                        padding: 4,
                        borderRadius: 2,
                        borderWidth: 0,
                        borderColor: "#FFFFFF",
                        opacity: 1,
                        dropShadow: {
                          enabled: false,
                        },
                      },
                      fontWeight: "bold",
                      fontSize: "26px",
                      fontFamily: "Helvetica, Arial, sans-serif",
                      offsetY: -8,
                      show: true,
                    },
                  },
                },
              },
              fill: {
                colors: [color],
              },
              stroke: {
                lineCap: "round",
                width: 2,
                colors: ["#FFFFFF"],
              },
              labels: [quality],
            };
            return (
              <Card
                key={year}
                title={
                  <span
                    style={{
                      color: "#B64CFF",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    {`Safra ${year.toString().slice(-2)} / ${(
                      Number(year) + 1
                    )
                      .toString()
                      .slice(-2)}`}
                  </span>
                }
                bordered={false}
                style={cardStyle}
              >
                <div>
                  {item ? (
                    (item.model_messages === "low_image_available" ||
                      item.model_messages === "unhandled_exception" ||
                      (item.risk_score === 0 && item.calculation_status === "error")) ? (
                      <div vertical>
                        <Flex vertical align="center">
                          <img
                            src="/icons/cloud.svg"
                            alt="Cloud Icon"
                            width={100}
                            height={100}
                          />

                          <div className="highCloudCoverageTitle">
                            Alta cobertura por nuvens
                          </div>
                          <div
                            style={{
                              display: "flex",
                              gap: "4px",
                              marginTop: "20px",
                            }}
                          >
                            <InfoCircleOutlined />
                            <div className="highCloudCoverageDescription">
                              Extenso período com excesso de nuvens neste
                              talhão. Impossibilitando o cálculo de
                              produtividade preciso.
                            </div>
                          </div>
                          <br />
                          <div style={{ height: "4px" }}></div>
                        </Flex>
                      </div>
                    ) : (
                      <div>
                        {(item.culture == 'soja') ? (
                          <ReactApexChart
                            options={chartOptions}
                            series={[riskScore / 10]}
                            type="radialBar"
                            height={128}
                          />) :
                          (<Flex vertical align="center"><img
                            src="/icons/grass-icon.svg"
                            alt="Plant Stress Icon"
                            width={136}
                            height={136}
                          /></Flex>
                          )}

                        {(item.culture == 'soja') ? (
                          <Flex gap={8}>
                            <img
                              src="/icons/grass-icon.svg"
                              alt="Plant Stress Icon"
                              width={36}
                              height={48}
                            />
                            <Flex vertical>
                              <Text
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                Estimativa de Culturas:
                                <br />
                              </Text>
                              <Text
                                style={{
                                  color: "#B64CFF",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "16px",
                                }}
                              >
                                {item.culture ? item.culture : "Analisando"}
                              </Text>
                            </Flex>
                          </Flex>) : (
                          <Flex vertical align="center">
                            <Text
                              style={{ fontSize: "12px", fontWeight: "600" }}
                            >
                              Estimativa de Culturas:
                              <br />
                            </Text>

                            <Text
                              style={{
                                color: "#B64CFF",
                                fontSize: "16px",
                                fontWeight: "800",
                                lineHeight: "16px",
                              }}
                            >
                              <br />{item.culture ? getCultureName(item.culture) : "Analisando"}
                            </Text>
                          </Flex>
                        )}

                        {(item.culture == 'soja') ? (
                          <Flex gap={8}>
                            <img
                              src="/icons/auto-graph.svg"
                              alt="Plant Stress Icon"
                              width={36}
                              height={48}
                            />
                            <Flex vertical>
                              <Text
                                style={{ fontSize: "12px", fontWeight: "600" }}
                              >
                                Estimativa de Produtividade:
                                <br />
                              </Text>
                              <Text
                                style={{
                                  color: "#B64CFF",
                                  fontSize: "16px",
                                  fontWeight: "400",
                                  lineHeight: "16px",
                                }}
                              >
                                {estimativaDeProdutividade}
                                <br />
                              </Text>
                            </Flex>
                          </Flex>) : ""}
                      </div>
                    )
                  ) : (!isCalculating ? (
                    <Flex vertical>
                      <Flex vertical align="center">
                        <img
                          src="/icons/grass-icon.svg"
                          alt="Plant Stress Icon"
                          width={136}
                          height={136}
                        />

                        <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                          Estimativa de Culturas:
                        </Text>
                        <Text
                          style={{
                            color: "#B64CFF",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                        >
                          Outra cultura
                        </Text>
                        <br />
                        <div style={{ height: "4px" }}></div>
                      </Flex>
                    </Flex>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "16rem",
                      }}
                    >
                      <Spin indicator={<LoadingOutlined style={{ fontSize: 128, color: "#B64CFF" }} spin />} />
                      <div style={{ height: "4px" }}></div>

                      <Flex gap={8}>
                        <img
                          src="/icons/grass-icon.svg"
                          alt="Plant Stress Icon"
                          width={36}
                          height={48}
                        />
                        <Flex vertical>
                          <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                            Estimativa de Culturas:
                            <br />
                          </Text>
                          <Text
                            style={{
                              color: "#B64CFF",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "16px",
                            }}
                          >
                            Analisando
                          </Text>
                        </Flex>
                      </Flex>

                      <Flex gap={8}>
                        <img
                          src="/icons/auto-graph.svg"
                          alt="Plant Stress Icon"
                          width={36}
                          height={48}
                        />
                        <Flex vertical>
                          <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                            Estimativa de Produtividade:
                            <br />
                          </Text>
                          <Text
                            style={{
                              color: "#B64CFF",
                              fontSize: "16px",
                              fontWeight: "400",
                              lineHeight: "16px",
                            }}
                          >
                            {estimativaDeProdutividade}
                          </Text>
                        </Flex>
                      </Flex>
                    </div>
                  ))}
                </div>
              </Card>
            );
          })}
      </div>
    </Card>
  );
};

export default HistoricalParameters;
