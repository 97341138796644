import { React, useState } from "react";
import {Button, Tooltip, Popconfirm, Dropdown, Space} from "antd";
import {
  CheckOutlined,
  StopOutlined,
  DeleteOutlined,
  MonitorOutlined,
  LoadingOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  updateTicketStatus,
  deleteUserTicket,
  deleteUserTicketFromListing,
  sendTicketToSense
} from "./api/api.js";

import "./CardComponent.css";
import getScoreInfo from "./dashboard/functions/GetScoreInfo.js";
import { parseCookies } from "nookies";
import getStatus from "./dashboard/Status.js";

const cookies = parseCookies();

const getRiskScoreIcon = (ticket) => {
  let statusObj = getStatus(ticket);

  // If Total Classifications are 0 and Risk score calculation either goes through or not
  // Return N/A
  if (statusObj.status !== "calculating") {
    // Process this block only if ticket is not in calculating state
    if (
      (ticket.total_classifications === 0
          && (ticket.risk_calculation_status === "success" || ticket.risk_calculation_status === "error"))
          || (ticket.total_classifications === 0 && statusObj.status == "monitoring"))
        return (
      <div
        className="listingCardRiskScore"
        style={{ color: "grey", fontWeight: 600 }}
      >
        N/A
      </div>
    );
  }

  switch (statusObj.status) {
    case "approved":
    case "inAnalysis":
    case "disapproved":
      let scoreInfo = getScoreInfo(ticket.risk_score);
      return (
        <div
          className="listingCardRiskScore"
          style={{
            backgroundColor: ticket.risk_score > 0 ? scoreInfo.color : '',
            color: ticket.risk_score == 0 ? scoreInfo.color : '',
          }}
        >
          {ticket.risk_score > 0 ? ticket.risk_score : "N/A"}
        </div>
      );
    case "calculating":
      if (ticket.consultation_status !== "M") {
        return <LoadingOutlined className="listingCardRiskScoreCalculating" />;
      } else
        return (
          <div
            className="listingCardRiskScore"
            style={{
              backgroundColor: "#FFF",
            }}
          />
        );
    case "monitoring":
      let scoreInfo2 = getScoreInfo(ticket.risk_score);
      return (
        <div
          className="listingCardRiskScore"
          style={{
            backgroundColor: ticket.risk_score > 0 ? scoreInfo2.color : '',
            color: ticket.risk_score == 0 ? scoreInfo2.color : '',
          }}
        >
          {ticket.risk_score > 0 ? ticket.risk_score : "N/A"}
        </div>
      );
    default:
      // Set the default state to N/A
      return <div
        className="listingCardRiskScore"
        style={{ color: "grey", fontWeight: 600 }}
      >
        N/A
      </div>;
  }
};

const CardComponent = ({ ticket, onStatusChange, onSendToSense, listUpdateOnDelete }) => {
  const [loadingMonitoring, setLoadingMonitoring] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleDelete = (ticket) => async (event) => {
    event.stopPropagation();
    try {
      await deleteUserTicket(ticket.id);
      await deleteUserTicketFromListing(ticket.id)
    } catch (error) {
      console.error("Error deleting ticket:", error);
    }
    listUpdateOnDelete();
  };

  const basePath = location.pathname;

  const handleApprove = (ticket) => async (event) => {
    event.stopPropagation();
    try {
      await updateTicketStatus(ticket, "A");
      onStatusChange(ticket.id, "A");
    } catch (error) {
      console.error("Error approving ticket:", error);
    }
  };

  const handleMonitor = (ticket) => async (event) => {
    event.stopPropagation();
    try {
      setLoadingMonitoring(true);
      await sendTicketToSense(ticket);
      onSendToSense(ticket.id);
      setLoadingMonitoring(false);
    } catch (error) {
      console.error("Error sending ticket to monitoring:", error);
    }
  };

  const handleReprove = (ticket) => async (event) => {
    event.stopPropagation();
    try {
      await updateTicketStatus(ticket, "R");
      onStatusChange(ticket.id, "R");
    } catch (error) {
      console.error("Error reproving ticket:", error);
    }
  };

  const handleNavigation = (event, path) => {
    const scrollPosition = window.scrollY;
    sessionStorage.setItem('scrollPosition', scrollPosition);

    if (event.button === 0 && (event.ctrlKey || event.metaKey)) {
      window.open(path, "_blank");
    } else if (event.button === 0) {
      navigate(path);
    } else if (event.button === 1) {
      window.open(path, "_blank");
    }
  };

  const menuItems = [
    {
      label: (
        <a href={`${basePath}/${ticket.id}`} target="_blank" rel="noopener noreferrer">
          Abrir em nova aba
        </a>
      ),
      key: "0",
    },
  ];

  return (
    <div className="listingCard">
      <div className="elementFlexHorizontal">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
          onClick={(event) => handleNavigation(event, `${basePath}/${ticket.id}`)}
          onContextMenu={(event) => event.preventDefault()} // Prevent default context menu
        >
          {["MU"].includes(cookies["role"]) && (
            <Tooltip title="Excluir">
              <Button
                className="deleteTicketButton"
                type="ghost"
                onClick={handleDelete(ticket)}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          )}
          <div style={{ width: "48px" }}>
            {getRiskScoreIcon(ticket)}
          </div>
          <div
            style={{ width: "200px", gap: "4px" }}
            className="elementFlexVertical"
          >
            <div className="textEllipsisTitle">
              {ticket.farm_name}
            </div>
            <div className="textEllipsis">{ticket.field_name}</div>
          </div>

          <div
            style={{ width: "240px", gap: "4px" }}
            className="elementFlexVertical"
          >
            <div
              className="textEllipsisTitle"
              style={{ display: "flex", gap: 4 }}
            >
              Proposta:{" "}
              <div style={{ color: "#9236D9" }}>
                {ticket.proposal_id}
              </div>
            </div>
            <div className="textEllipsisGray">
              Cultura: {ticket.crop_type}
            </div>
          </div>

          <div
            style={{ width: "160px", gap: "4px" }}
            className="elementFlexVertical"
          >
            <div className="textEllipsisTitle">
              {Math.floor(ticket.hectares)} Hectares
            </div>
            <div className="textEllipsisGray">
              {ticket.municipality}
            </div>
          </div>

          <div
            style={{ width: "128px", gap: "4px" }}
            className="elementFlexVertical"
          >
            <div className="textEllipsis">Status:</div>
            <div
              className="textEllipsisTitle"
              style={{
                color: getStatus(ticket).color,
              }}
            >
              {getStatus(ticket).label}
            </div>
          </div>
        </div>

        <div style={{ display: "flex", gap: "32px", alignItems: "center" }}>
          <div className="listingCardButtonArea">
            {ticket.consultation_status === "P" &&
              ticket.risk_calculation_status === "success" &&
              ticket.risk_score > 0 && (
                <div style={{ display: "flex", gap: 8 }}>
                  <Popconfirm
                    title="Tem certeza que deseja reprovar esse ticket?"
                    description="Esta ação não é reversível."
                    onConfirm={handleReprove(ticket)}
                    onCancel={(e) => e.stopPropagation()}
                    okText="Sim"
                    cancelText="Não"
                    placement="bottom"
                  >
                    <Tooltip title="Reprovar">
                      <Button
                        className="approveDisapproveButton"
                        type="primary"
                        danger
                        icon={<StopOutlined />}
                        onClick={(e) => e.stopPropagation()}
                        style={{ backgroundColor: "#FF4D4F" }}
                      />
                    </Tooltip>
                  </Popconfirm>
                  <Tooltip title="Aprovar">
                    <Button
                      className="approveDisapproveButton"
                      type="primary"
                      icon={<CheckOutlined />}
                      onClick={handleApprove(ticket)}
                      style={{ backgroundColor: "#52C41A" }}
                    />
                  </Tooltip>
                  <Tooltip title="Mais opções">
                    <Dropdown
                      menu={{ items: menuItems }}
                      trigger={["click"]}
                    >
                      <Space>
                        <Button
                          danger
                          size="large"
                          type="secondary"
                          icon={<MoreOutlined rotate={90} />}
                          className="approveDisapproveButton"
                          style={{ backgroundColor: "#f5f5f5" }}
                        />
                      </Space>
                    </Dropdown>
                  </Tooltip>
                </div>
              )}
            {(ticket.consultation_status === "A" &&
              !ticket.sense_status) && (
                <Button
                  className="monitorButton"
                  type="primary"
                  icon={<MonitorOutlined />}
                  loading={loadingMonitoring}
                  onClick={handleMonitor(ticket)}
                >
                  Monitorar
                </Button>
              )}
          </div>
          <div
            className="listingCardMap"
            dangerouslySetInnerHTML={{
              __html: ticket.map_html,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CardComponent;