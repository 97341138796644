import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(isLeapYear);

import "./DistributionCarChart.css";

const DistributionCarChart = ({
    totalArea,
    identifiedArea,
    identifiedAreaPercentage,
    consultedArea,
    consultedAreaPercentage,
    esgArea,
    esgAreaPercentage,
    unclassifiedArea,
    unclassifiedAreaPercentage,
}) => {

    let pieChartData = { series: [], options: {} };

    pieChartData = {
        series: [
            identifiedArea,
            consultedArea,
            esgArea,
            unclassifiedArea,
        ],
        options: {
            chart: {
                type: "pie",
            },
            labels: ["Área identificada", "Área consultada", "Áreas de ESG", "Área não-classificada"],
            colors: ["#FFC53D", "#D8AECE", "#389E0D", "#FF4D4F"],
            dataLabels: {
                enabled: false,
                enabledOnSeries: [1],
            },
            tooltip: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200,
                        },
                    },
                },
            ],
        },
    };

    return (
        <div
            className="distributionCarChartCard"
        >
            <div className='distributionCarChartTitle'>Uso da área do CAR</div>
            <div className='distributionCarChartContent'>
                <div
                    className="distributionCarChartContainer"
                >
                    <ReactApexChart
                        options={pieChartData.options}
                        series={pieChartData.series}
                        type="pie"
                        width="100%"
                    />
                </div>
                <div className='distributionCarChartLegend'>
                    <div className='distributionCarChartLegendLine'>
                        <div className='distributionCarChartLegendTitle'>
                            Área total do CAR
                        </div>
                        <Divider type="vertical" />
                        <div
                            className='distributionCarChartLegendDescription'
                            style={{ minWidth: "220px", textAlign: "right" }}>
                            Porcentagem referente ao total do CAR:
                        </div>
                        <div
                            style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(totalArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                    {identifiedArea > 0 && <div className='distributionCarChartLegendLine'>
                        <div className='distributionCarChartLegendDot'>
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#FFC53D",
                                }}
                            ></div>
                            Área identificada
                        </div>
                        <Divider type="vertical" />
                        <div style={{ minWidth: "220px", textAlign: "right" }}>
                            {identifiedAreaPercentage.toFixed(2)}%
                        </div>
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(identifiedArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>}
                    <div className='distributionCarChartLegendLine'>
                        <div className='distributionCarChartLegendDot'>
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#D8AECE",
                                }}
                            ></div>
                            Área consultada
                        </div>
                        <Divider type="vertical" />
                        <div style={{ minWidth: "220px", textAlign: "right" }}>
                            {consultedAreaPercentage.toFixed(2)}%
                        </div>
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(consultedArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                    <div className='distributionCarChartLegendLine'>
                        <div className='distributionCarChartLegendDot'>
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#389E0D",
                                }}
                            ></div>
                            Áreas de ESG
                        </div>
                        <Divider type="vertical" />
                        <div style={{ minWidth: "220px", textAlign: "right" }}>
                            {esgAreaPercentage.toFixed(2)}%
                        </div>
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(esgArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                    <div className='distributionCarChartLegendLine'>
                        <div className='distributionCarChartLegendDot'>
                            <div
                                style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "50%",
                                    backgroundColor: "#FF4D4F",
                                }}
                            ></div>
                            Área não-classificada
                        </div>
                        <Divider type="vertical" />
                        <div style={{ minWidth: "220px", textAlign: "right" }}>
                            {unclassifiedAreaPercentage.toFixed(2)}%
                        </div>
                        <div style={{ minWidth: "68px", textAlign: "right" }}>
                            {Math.floor(unclassifiedArea).toLocaleString('pt-BR')} Ha
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DistributionCarChart;
