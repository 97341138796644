import React from "react";
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import VisionErrorPopover from "./VisionErrorPopover";
import { polygonStylePatterns } from "./polyStyles";
import "./Vision.css"

const VisionMapOne = ({ data }) => {
  //console.log(data)
  return (
    <div
      className="visionReportElementCard"
      style={{
        height: "768px",
        position: "relative",
        borderRadius: "8px",
        backgroundColor: "white",
      }}
    >
      <div className="visionMapLegendCard">
        <div className="visionMapLegendTitle"> Legenda </div>
        <div className="visionMapLegendContent">
          <div className="visionMapLegendLine">
            <div
              className="visionMapLegendSquare"
              style={{ backgroundColor: "#D8AECE", }}
            />
            <div className="visionLegendText">Área do Talhão</div>
          </div>
          <div className="visionMapLegendLine">
            <div
              className="visionMapLegendSquare"
              style={{ backgroundColor: "#1890FF" }}
            />
            <div className="visionLegendText">Área do CAR</div>
          </div>
        </div>
      </div>
      <div style={{ height: "100%", width: "100%", padding: "4px" }}>
        <MapContainer
          center={[
            data?.center_coords?.lat || -14.235,
            data?.center_coords?.long || -51.9253,
          ]}
          zoom={data ? 15 : 5}
          maxZoom={16}
          zoomControl={false}
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <TileLayer url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          {data?.area_imovel && data.area_imovel.features.map((feature, index) => (
            <GeoJSON
              key={index}
              data={feature}
              style={polygonStylePatterns["carArea"]}
            />
          ))}
          {data?.ticket_geometry && (
            <GeoJSON
              data={data.ticket_geometry}
              style={polygonStylePatterns["kmlArea"]}
            />
          )}
        </MapContainer>
        {data?.status === "area_imovel_not_found" && <VisionErrorPopover />}
      </div>
    </div>
  );
};

export default VisionMapOne;
